@import 'src/scss/variables';
@import 'src/scss/utilities/breakpoints';
@import 'src/scss/utilities/mixins';

.quiz-number-field--wrapper {
    @apply flex flex-col;
    @include between-zero-lg {
        @apply gap-6;
    }
    @include lg-up {
        @apply gap-8;
    }
    .quiz-field-header {
        @apply relative flex items-start;
        @include between-zero-lg {
            @apply max-h-[8rem];
        }
        @include lg-up {
            @apply max-h-[6.25rem];
        }
        .quiz-field-icon {
            @apply max-h-full shrink;
            width: unset !important;
            object-fit: contain;
            @include between-zero-lg {
                @apply max-h-[4rem];
            }
            @include lg-up {
                @apply max-h-[6.25rem];
            }
        }
    }
    .quiz-info {
        &--wrapper {
            svg {
                path,
                polygon {
                    color: $green-faint-dark;
                    :global(.theme-moshy) & {
                        color: $purple-dark;
                    }
                }
            }
        }
        &--message {
            color: $green-faint-dark;
            :global(.theme-moshy) & {
                color: $grey-400;
            }
        }
    }
    .quiz-field-content {
        @apply flex flex-col gap-2;
        .explanation {
            @apply m-0;
            color: $green-faint-dark;
            :global(.theme-moshy) & {
                color: $grey-400;
            }
        }
        .question {
            @apply flex justify-start gap-2;
            .question--start,
            .question--input,
            .question--end {
                color: $green-dark;
                :global(.theme-moshy) & {
                    color: $black;
                }
            }
            .question--input {
                @apply shrink text-center;
                border-bottom: 2px solid $grey-300;
                color: $black;
                // hiding number input arrows
                -moz-appearance: textfield;
                @include between-zero-lg {
                    @apply w-[3.125rem];
                }
                @include lg-up {
                    @apply w-[5rem];
                }
                &:focus {
                    outline: none;
                }
            }
            .question--input::-webkit-outer-spin-button,
            .question--input::-webkit-inner-spin-button {
                @apply m-0;
                -webkit-appearance: none;
            }
        }
    }
    .error-message {
        @apply m-0 flex items-center gap-2 text-red-error;
    }
}

.large-image-container {
    @apply flex items-center justify-center;
    width: 100%;
    margin-bottom: 2rem;
    position: relative;
    max-height: none;
    height: auto;
    overflow: visible;
}

.large-image {
    max-width: 100%;
    max-height: none !important;
    height: auto !important;
    width: auto;
    object-fit: contain;
    position: relative;
    z-index: 1;
    margin-bottom: 1.5rem;
}
