@import 'src/scss/variables';
@import 'src/scss/utilities/breakpoints';
@import 'src/scss/utilities/mixins';

.quiz-rating--wrapper {
    @apply flex flex-col;
    @include between-zero-lg {
        @apply gap-6;
    }
    @include lg-up {
        @apply gap-8;
    }
    .quiz-field-header {
        @apply flex flex-col;
        .title {
            color: $green-dark;
            :global(.theme-moshy) & {
                color: $black;
            }
        }
        .subtitle {
            @apply m-0;
            color: $green-faint-dark;
            :global(.theme-moshy) & {
                color: $grey-400;
            }
        }
    }
    .rating-list {
        @apply flex flex-nowrap;
        @include between-zero-lg {
            @apply justify-center gap-2 h-[4rem];
        }
        @include lg-up {
            @apply px-7 gap-5 h-[5rem];
        }
        .rating-item {
            @apply flex grow shrink items-center justify-center rounded-lg gap-3 h-full;
            border: 1px solid $grey-300;
            svg {
                @apply w-[1.5rem];
                color: $grey-300;
            }
            &.item-selected svg,
            &.item-hovered svg {
                color: $white;
            }
            &.item-hovered {
                background-color: $green-faint-dark;
                border-color: $green-faint-dark;
                :global(.theme-moshy) & {
                    background-color: $purple;
                    border-color: $purple;
                }
            }
            &.item-selected {
                background-color: $green-dark;
                border-color: $green-dark;
                :global(.theme-moshy) & {
                    background-color: $purple-dark;
                    border-color: $purple-dark;
                }
            }
        }
    }
}
