@import 'src/scss/utilities/breakpoints';
@import 'src/scss/utilities/mixins';
@import 'src/scss/variables';

.quiz-wrapper {
    @apply fixed flex h-full w-full flex-col overflow-hidden;

    .header {
        @apply flex h-[6.25rem] w-full items-center justify-center py-[1.875rem];
        @include between-zero-lg {
            @apply h-[3.5rem] py-[0.75rem];
        }
        .header-logo {
            @apply h-[2.5rem];
            @include between-zero-lg {
                @apply h-[1.875rem];
            }
            > * {
                fill: $green-dark;
            }
        }
    }
    .content-wrapper {
        @apply flex-grow overflow-y-auto opacity-100 transition-opacity duration-200 ease-in-out;
        // Mobile
        @include between-zero-lg {
            @apply top-1/2 flex items-center justify-center;
            .content {
                @apply w-full px-4 py-4;
                max-width: 42.125rem;
            }
        }
        // Desktop
        @include lg-up {
            @apply top-1/2 flex items-center justify-center;
            .content {
                @apply w-[33vw];
                min-width: 24.125rem;
            }
            &.content-overflowing .content {
                width: 42.125rem;
            }
        }
        .content {
            @apply max-h-full py-4;
        }
        &.content-overflowing {
            border-top: 1px solid $grey-200;
            border-bottom: 1px solid $grey-200;
            .content > div {
                @apply pb-4;
            }
        }
        &:not(.content--loaded) {
            @apply opacity-0;
        }
    }

    .footer-wrapper.footer-wrapper--mini {
        @include between-zero-lg {
            @apply h-0 opacity-0;
            display: none;
        }
    }
    .footer-wrapper.footer-wrapper--sticky {
        @include lg-up {
            &.footer--hidden {
                @apply h-0 opacity-0;
                display: none;
            }
        }
    }
    .footer {
        @apply flex items-center justify-between;
        > div {
            @apply flex items-center px-4 py-3;
        }
        .footer-nav {
            @apply gap-3;
            button {
                @apply flex h-10 w-10 items-center justify-center rounded p-0;
                padding: unset;
                border: 2px solid;
                :global(.theme-mosh) & {
                    border-color: $green-faint-dark;
                    color: $green-faint-dark;
                }
            }
        }
        .footer-actions {
            @apply flex gap-4;
            .footer-help-text {
                color: $grey-400;
                &.text-disabled {
                    color: $grey-300;
                }
                svg {
                    @apply ml-1;
                }
                @include between-zero-lg {
                    @include hide-element;
                }
            }
            .footer-action-button {
                @apply px-6 py-4 normal-case tracking-normal;
            }
            .footer-action-link {
                @apply m-0 px-6 py-4;
                color: $green-faint-dark;
                :global(.theme-moshy) & {
                    color: $purple-dark;
                }
            }
        }

        &.footer-mini {
            @apply mt-4 flex gap-4 pb-4 pt-4;
            > div {
                @apply mt-0 p-0;
            }
            .footer-actions {
                .footer-help-text {
                    color: $grey-400;
                    &.text-disabled {
                        color: $grey-300;
                    }
                }
            }
        }
    }
}
