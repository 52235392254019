@import 'src/scss/variables';
@import 'src/scss/utilities/breakpoints';
@import 'src/scss/utilities/mixins';

.quiz-text-field--wrapper {
    @apply flex flex-col;
    @include between-zero-lg {
        @apply gap-6;
    }
    @include lg-up {
        @apply gap-8;
    }
    .quiz-field-header {
        @apply flex flex-col;
        .title {
            color: $green-dark;
            :global(.theme-moshy) & {
                color: $black;
            }
        }
        .subtitle {
            color: $green-faint-dark;
            :global(.theme-moshy) & {
                color: $grey-400;
            }
        }
    }
    .textarea--wrapper {
        textarea {
            @apply p-4 font-normal not-italic;
            letter-spacing: -0.01em;
        }
    }
    .default-field {
        @apply w-full resize-none overflow-hidden px-3 py-2 border rounded-lg border-grey-300;
        @apply focus:outline-none focus:border-2 focus-visible:border-blue-info;
    }
    .error-field {
        @apply w-full px-3 py-2 border-2 rounded-lg border-red-error;
        @apply active:border-red-error focus:border-red-error focus:outline-none focus:ring-red-error;
        @apply focus-visible:border-blue-info;
    }
    .error-message {
        @apply flex pl-4 leading-5 place-items-center text-red-error;
        svg {
            @apply mr-1 mb-1;
        }
    }
}
