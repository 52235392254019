@import 'src/scss/variables';
@import 'src/scss/utilities/breakpoints';
@import 'src/scss/utilities/mixins';

.quiz-number-rating--wrapper {
    .quiz-field-header {
        .title {
            color: $green-dark;
            :global(.theme-moshy) & {
                color: $black;
            }
        }
        .subtitle {
            @apply m-0;
            color: $green-faint-dark;
            :global(.theme-moshy) & {
                color: $grey-400;
            }
        }
    }
    .quiz-rating-wrapper {
        @apply mt-8;
        .quiz-rating {
            .heading {
                @apply mb-4;
                color: $green-dark;
            }
            button {
                @apply py-4 px-7 lg:px-16 lg:py-6 border rounded-[0.5rem];
                border-color: $grey-300;
                &:hover {
                    border-color: $green-faint-dark;
                    background: $green-faint-dark;
                    :global(.theme-moshy) & {
                        border-color: $purple;
                        background: $purple;
                    }
                    color: white;
                }
                &.selected {
                    border-color: $green-dark;
                    background-color: $green-dark;
                    :global(.theme-moshy) & {
                        border-color: $purple-dark;
                        background: $purple-dark;
                    }
                    color: white;
                }
            }
        }
    }
}
