@import 'src/scss/variables';
@import 'src/scss/utilities/breakpoints';

.input {
    &-wrapper {
        @apply relative flex items-center mt-6;
        input {
            @apply rounded-lg w-full;
            &::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                opacity: 1; /* Firefox */
                @apply text-grey-300;
            }
            :focus {
                outline: 2px solid blue;
            }
        }
    }

    &--indicator {
        @apply absolute left-0 p-4 border-r border-grey-300 inline-block text-grey-400;
    }
    &--default {
        &:focus-visible {
            @apply border-2 border-blue-info;
            @apply outline-2 outline-blue-info;
            overflow: hidden;
        }
    }
    &--error {
        &:focus-visible {
            @apply border-2 border-pink-dark;
            @apply outline-2 outline-pink-dark;
            overflow: hidden;
        }
    }

    &-blur--error {
        &:not(:focus) {
            @apply border-2 border-pink-dark;
        }
    }

    &-blur--default {
        &:not(:focus) {
            @apply border border-grey-300;
        }
    }

    &--error-message {
        @apply text-pink-dark;
    }
}

.quiz {
    &--heading {
        color: $green-dark;
        :global(.theme-moshy) & {
            color: $black;
        }
    }

    &-info {
        &--wrapper {
            svg {
                path,
                polygon {
                    color: $green-faint-dark;
                    :global(.theme-moshy) & {
                        color: $purple-dark;
                    }
                }
            }
        }
        &--message {
            color: $green-faint-dark;
            :global(.theme-moshy) & {
                color: $grey-400;
            }
        }
    }
}

.quiz-field-header {
    @apply flex items-start relative mb-8;
    @include between-zero-lg {
        @apply max-h-[4rem];
    }
    @include lg-up {
        @apply max-h-[6.25rem];
    }
    .quiz-field-icon {
        @apply shrink max-h-full;
        width: unset !important;
        object-fit: contain;
        @include between-zero-lg {
            @apply max-h-[4rem];
        }
        @include lg-up {
            @apply max-h-[6.25rem];
        }
    }
}
