@import '../../scss/variables';
@import '../../scss/utilities/breakpoints';

.logo {
    max-width: 5.5rem;
    width: 100%;
    svg {
        display: block;
        width: 100%;
        height: auto;
        path,
        polygon {
            fill: $green-dark;
        }
        enable-background: new 0 0 475.1 229.2;
    }
    &[data-variant='dark'] {
        svg {
            path,
            polygon {
                fill: $green-dark;
            }
        }
    }
    &[data-variant='light'] {
        svg {
            path,
            polygon {
                fill: $sand-light;
            }
        }
    }
    &[data-variant='white'] {
        svg {
            path,
            polygon {
                fill: $white;
            }
        }
    }
    @include md-up {
        max-width: 6.5rem;
    }
}
