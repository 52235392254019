@import 'src/scss/variables';
@import 'src/scss/utilities/breakpoints';
@import 'src/scss/utilities/mixins';

.quiz-prefilled--wrapper {
    @apply flex flex-col gap-4;
    @include between-zero-lg {
        @apply gap-4;
    }
    @include lg-up {
        @apply gap-6;
    }
    .quiz-prefilled-header {
        @apply flex flex-col;
        .title {
            color: $green-dark;
        }
        .subtitle {
            @apply m-0;
            color: $green-faint-dark;
        }
    }
    .answer-section-list {
        @apply flex;
        @include between-zero-lg {
            @apply flex-col gap-4;
        }
        @include lg-up {
            @apply flex-wrap gap-6;
        }
        .section-item-wrapper {
            @apply flex flex-col gap-2;
            @include between-zero-lg {
                @apply w-full;
            }
            @include lg-up {
                width: calc(50% - 0.75rem);
            }
            .section-item {
                @apply rounded-lg p-4 cursor-pointer;
                border: 1px solid $grey-300;
                &:hover .section-header .section-edit {
                    color: $green-dark;
                }
                .section-header {
                    @apply flex items-center justify-between gap-4 mb-4;
                    .section-name {
                        @apply m-0;
                    }
                    .section-edit {
                        color: $grey-300;
                    }
                }
                .answer-item-list {
                    @apply flex flex-col gap-4 m-0 p-0;
                    .answer-item {
                        @apply flex flex-col;
                        .question-name,
                        .question-answer {
                            color: $grey-400;
                        }
                    }
                }
                &.error-field {
                    @apply border-red-error;
                    border-width: 2px;
                }
            }
            .error-message {
                @apply flex px-4 gap-4 text-sm leading-5 place-items-center text-red-error mb-0;
            }
        }
    }
}
