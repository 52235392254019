@import 'src/scss/variables';
@import 'src/scss/utilities/breakpoints';

.header {
    @apply flex h-[6.25rem] w-full items-center justify-center py-[1.875rem];
    @include between-zero-lg {
        @apply h-[3.5rem] py-[0.75rem];
    }
    .header-logo {
        @apply h-[2.5rem];
        @include between-zero-lg {
            @apply h-[1.875rem];
        }
        > * {
            fill: $green-dark;
        }
    }
}

.quizzes {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100vh - var(--header-height, 0rem));
    background-color: $white;
    padding: 1rem;
    h5 {
        color: $black;
        :global(.theme-mosh) & {
            color: $green-dark;
        }
    }
    &--container {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        @include sm-up {
            width: 420px;
        }
        gap: 0.5rem;
    }
    &--header {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
        background-color: $grey-100;
        border-radius: 1rem;
        padding: 1rem;
        width: 100%;
        margin-bottom: 0.5rem;
        > div {
            flex: 1;
        }
        picture {
            position: relative;
            height: 4rem;
            width: 4rem;
            @include sm-up {
                height: 6rem;
                width: 6rem;
            }
            border-radius: 50%;
            overflow: hidden;
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                object-fit: cover;
            }
        }
    }
    ul {
        display: contents;
        overflow: hidden;
        > * {
            flex: 1;
            width: 100%;
            max-height: 4rem;
        }
        a {
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 0.75rem;
            padding: 0 1.5rem;
            border-radius: 1rem;
            border: 1px solid $grey-200;
            box-shadow: 0 3px 15px 0 #844f7e0d;
            h5 {
                line-height: 100%;
            }
            svg {
                color: $grey-400;
            }
            &:hover {
                background-color: $green-light;
                :global(.theme-moshy) & {
                    background-color: $blue-extra-light;
                }
            }
        }
    }
}
