@import 'src/scss/variables';
@import 'src/scss/utilities/breakpoints';
@import 'src/scss/utilities/mixins';

.quiz-content {
    @apply flex flex-col gap-4;
    .title {
        color: $green-dark;
        :global(.theme-moshy) & {
            color: $black;
        }
    }
    .subtitle {
        color: $green-faint-dark;
        :global(.theme-moshy) & {
            color: $grey-400;
        }
    }
    .light-icon {
        color: $green-faint-light;
        :global(.theme-moshy) & {
            color: $grey-300;
        }
    }
    .disabled {
        .title,
        .subtitle {
            color: $grey-300;
        }
    }
    &--border-box {
        @apply rounded-lg;
        border: 1px solid $grey-200;
        &--footer {
            @apply rounded-b-lg;
            border-top: 1px solid $grey-200;
            background-color: $grey-100;
            color: $green-faint-dark;
            :global(.theme-moshy) & {
                color: $grey-400;
            }
        }
    }
}
.hover-border {
    &:not(.hover-border--disabled):hover {
        outline: 2px solid $green;
        outline-offset: -2px;
        :global(.theme-moshy) & {
            outline: 2px solid $purple;
        }
    }
}
