@import 'src/scss/variables';
@import 'src/scss/utilities/breakpoints';
@import 'src/scss/utilities/mixins';

.quiz-select-option--wrapper {
    @apply flex flex-col;
    @include between-zero-lg {
        @apply gap-6;
    }
    @include lg-up {
        @apply gap-8;
    }
    .quiz-field-header {
        @apply flex flex-col;
        .title {
            color: $green-dark;
            :global(.theme-moshy) & {
                color: $black;
            }
        }
        .subtitle {
            @apply m-0;
            color: $green-faint-dark;
            :global(.theme-moshy) & {
                color: $grey-400;
            }
        }
    }
    .answers-list {
        @apply flex flex-col;
        @include between-zero-lg {
            @apply gap-2;
        }
        @include lg-up {
            @apply gap-3;
        }
        .answer-item {
            @apply relative flex w-full cursor-pointer items-center justify-between rounded-lg p-4;
            border: 1px solid $grey-300;
            @include between-zero-md {
                @apply flex-col items-start gap-2;
            }
            @include md-up {
                @apply flex-row items-start justify-between;
            }
            &--text {
                @apply flex-1 text-left first-letter:capitalize;
                color: $green-dark;
                :global(.theme-moshy) & {
                    color: $black;
                }
            }
            &--explanation {
                color: $green-faint-dark;
                :global(.theme-moshy) & {
                    color: $grey-400;
                }
            }
            &--price {
                &--start {
                    color: $green-dark;
                    :global(.theme-moshy) & {
                        color: $black;
                    }
                }
                &--end {
                    color: $green-faint-dark;
                    :global(.theme-moshy) & {
                        color: $grey-400;
                    }
                }
            }
            &.answer-item--selected {
                border-color: $green-dark;
                background-color: $green-dark;
                :global(.theme-moshy) & {
                    border-color: $purple-dark;
                    background-color: $purple-dark;
                }
                .answer-item--text,
                .answer-item--explanation,
                .answer-item--price .answer-item--price--start {
                    color: $white;
                }
                .answer-item--price .answer-item--price--end {
                    color: $green-faint-light;
                    :global(.theme-moshy) & {
                        color: $grey-400;
                    }
                }
            }
            &:hover:not(.answer-item--selected) {
                border-color: $green-faint-dark;
                background-color: $green-faint-dark;
                :global(.theme-moshy) & {
                    border-color: $purple;
                    background-color: $purple;
                }
                .answer-item--text,
                .answer-item--explanation,
                .answer-item--price .answer-item--price--start {
                    color: $white;
                    :global(.theme-moshy) & {
                        color: $purple-dark;
                    }
                }
                .answer-item--price .answer-item--price--end {
                    color: $white;
                    :global(.theme-moshy) & {
                        color: $grey-400;
                    }
                }
            }
            &.answer-item--not-sure .answer-item--text {
                @apply text-center;
            }
        }
        .answer-list--divider {
            @apply text-center;
            color: $grey-400;
        }
    }
    .quiz-info {
        &--wrapper {
            svg {
                path,
                polygon {
                    color: $green-faint-dark;
                    :global(.theme-moshy) & {
                        color: $purple-dark;
                    }
                }
            }
        }
        &--message {
            color: $green-faint-dark;
            :global(.theme-moshy) & {
                color: $grey-400;
            }
        }
    }
}
