@import 'src/scss/utilities/breakpoints';
@import 'src/scss/variables';

.overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 61, 59, 0.4);
    backdrop-filter: blur(4px);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(4px);
    z-index: 100;
    display: flex;
    &:global(.theme-moshy) {
        background-color: rgba(224, 232, 252, 0.25);
    }
}
.window {
    @apply absolute flex justify-center md:items-center outline-none;
    width: calc(100% - 2rem);
    height: calc(100% - 2rem);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include md-up {
        width: auto;
        height: auto;
        min-width: 354px;
        max-width: 354px;
        &.full {
            width: calc(100% - 2rem);
            height: calc(100% - 2rem);
        }
    }
    @include between-sm-md {
        width: auto;
        height: auto;
        min-width: 354px;
        max-width: 354px;
        &.full {
            width: calc(100% - 2rem);
            height: calc(100% - 2rem);
        }
    }
    @include between-zero-md {
        width: calc(100% - 1rem);
        height: auto;
        padding-left: 2rem;
        padding-right: 2rem;
    }
    &.minimal {
        height: 90vw;
    }

    &.custom {
        .close {
            top: 1.7rem;
            right: 1rem;
            @include md-up {
                top: 0.9rem;
            }
            svg {
                @apply w-2 h-auto;
                path {
                    @apply fill-grey-300;
                }
                @include between-zero-md {
                    @apply w-[2px] h-auto;
                }
                @include md-up {
                    @apply w-[0.65rem] h-auto;
                }
            }
        }
    }

    @include phone-only {
        top: auto;
        bottom: 0;
        height: calc(100% - 2rem);
        transform: translate(-50%, 100%);
        width: 95%;
        animation: modalSlideUp 400ms ease forwards;
        &.custom {
            @apply w-full bottom-auto h-auto px-4 block relative self-center;
        }
    }
    @include between-zero-md {
        top: auto;
        bottom: 0;
        height: calc(100% - 2rem);
        transform: translate(-50%, 100%);
        width: 95%;
        animation: modalSlideUp 400ms ease forwards;
        &.custom {
            @apply w-full bottom-auto h-auto px-4 block relative self-center;
            .close {
                top: 0.9rem;
                right: 2rem;
                svg {
                    @apply w-2.5 h-auto;
                }
            }
        }
    }
}

@keyframes modalSlideUp {
    100% {
        transform: translate(-50%, 0);
    }
}
.content {
    @apply p-4 relative overflow-y-auto outline-none rounded-2xl;
    @apply w-full h-full overflow-auto;
    &.full {
        @apply inset-0;
    }
    @include md-up {
        max-height: 90vh;
    }
    background-color: $white;
    box-shadow: 0 5px 20px -10px rgba(19, 40, 18, 0.1);
    border: 1px solid $grey-light;
}

.close {
    @apply absolute top-4 right-4 md:top-8 md:right-8 z-20;
    background-color: inherit;
    svg {
        @apply w-4 md:w-4 h-auto;
    }
}
