@import 'src/scss/utilities/breakpoints';
@import 'src/scss/utilities/mixins';
@import 'src/scss/variables';

.quiz--onboarding {
    @apply flex flex-col gap-8;
    @include between-zero-lg {
        @apply gap-6;
    }
    .icon-wrapper {
        @apply relative flex h-[7.5rem] items-end;
        @include between-zero-lg {
            @apply h-[4rem];
        }
        .icon {
            @apply max-h-full shrink;
            width: unset !important;
            object-fit: contain;
        }
    }
    .title {
        @apply text-white;
        :global(.theme-moshy) & {
            @apply text-green-dark;
        }
    }
    .subtitle {
        @apply text-white;
        :global(.theme-moshy) & {
            @apply text-black;
        }
    }
    .spacer {
        @apply w-6;
        border: 2px solid $white;
        border-radius: 1rem;
        :global(.theme-moshy) & {
            border-color: $black;
        }
    }
    .etc-badge {
        @apply flex items-center gap-2 self-start px-[0.75rem] py-2;
        background-color: $green-light;
        color: $green-dark;
        border-radius: 2rem;

        :global(.theme-moshy) & {
            background-color: $purple-light;
            color: $purple-dark;
        }
    }
    .disclaimer {
        p {
            @apply mb-0 text-xs;
            color: $green-faint-light;

            :global(.theme-moshy) & {
                @apply text-grey-400;
            }

            a {
                text-decoration: underline;
                font-weight: bold;
            }
        }
    }
    .section-wrapper,
    .section-wrapper--tight {
        @apply flex flex-col items-start gap-4;
    }
    .section-wrapper--tight {
        @include between-zero-lg {
            @apply gap-2;
        }
    }
}
