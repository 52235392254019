@import 'src/scss/variables';
@import 'src/scss/utilities/breakpoints';
@import 'src/scss/utilities/mixins';

.quiz-field-header {
    @apply flex flex-col gap-2 mb-6 lg:mb-8;
    .question-text {
        color: $green-faint-dark;
        :global(.theme-moshy) & {
            color: $grey-400;
        }
    }
    .answer--wrapper {
        @apply flex flex-col gap-2;
        .answer-text {
            @apply w-fit px-2 py-1 cursor-default first-letter:capitalize m-0 text-white rounded-lg mb-0;
            background-color: $green-faint-dark;
            :global(.theme-moshy) & {
                background-color: $purple-dark;
            }
        }
    }
}
