@import 'src/scss/variables';
@import 'src/scss/utilities/breakpoints';
@import 'src/scss/utilities/mixins';

.quiz-select-option--wrapper {
    @apply flex flex-col;
    @include between-zero-lg {
        @apply gap-6;
    }
    @include lg-up {
        @apply gap-8;
    }
    .quiz-field-header {
        @apply flex flex-col;
        .title {
            color: $green-dark;
            :global(.theme-moshy) & {
                color: $black;
            }
        }
        .subtitle {
            @apply m-0;
            color: $grey-400;
        }
    }
    .wait-time--wrapper {
        @apply mt-2;
        background-color: $grey-100;
        color: $green-faint-dark;
    }
    .answers-list.item--wrap {
        @include lg-up {
            @apply flex-wrap;
        }
        .answer-item {
            @include lg-up {
                width: calc(50% - 0.5rem);
            }
        }
    }
    .answers-list:not(.item--wrap) {
        @include lg-up {
            @apply flex-col;
        }
        .answer-item {
            @include lg-up {
                width: calc(100% - 0.5rem);
            }
        }
    }
    .answers-list {
        @apply flex gap-4;
        @include between-zero-lg {
            @apply flex-col;
        }
        // Don't show hover states on touch devices
        @media (hover: hover) {
            .answer-item:hover {
                border-color: $green-faint-dark;
                background-color: $green-faint-dark;
                :global(.theme-moshy) & {
                    border-color: $purple;
                    background-color: $purple;
                }
                .item-number {
                    border-color: $white;
                    :global(.theme-moshy) & {
                        border-color: $purple-dark;
                    }
                }
                .item-number,
                .item-text {
                    color: $white;
                    :global(.theme-moshy) & {
                        color: $purple-dark;
                        font-weight: $font-weight-bold;
                    }
                }
                .info-button svg path,
                .info-button svg polygon {
                    color: $white;
                    :global(.theme-moshy) & {
                        color: $purple-dark;
                    }
                }
                &.item-selected {
                    .item-number {
                        border-color: $white;
                    }
                    .item-number,
                    .item-text,
                    .info-button svg path,
                    .info-button svg polygon {
                        color: $white;
                    }
                }
            }
        }
        .answer-item {
            @apply relative flex w-full items-center justify-between gap-3 rounded-lg p-4;
            border: 1px solid $grey-300;
            .item-number {
                @apply flex h-6 w-6 shrink-0 items-center justify-center rounded;
                border: 1px solid $green-faint-dark;
                color: $green-faint-dark;
                :global(.theme-moshy) & {
                    border-color: $purple-dark;
                    color: $purple-dark;
                }
            }
            .item-text {
                @apply flex-1 text-left first-letter:capitalize;
                color: $grey-400;
            }
            .info-button {
                @apply flex rounded-full;
                outline: none;
                border: none;
                svg path,
                svg polygon {
                    @apply text-green-faint-dark;
                    :global(.theme-moshy) & {
                        color: $purple-dark;
                    }
                }
            }
            &.item-selected {
                border-color: $green-dark;
                background-color: $green-dark;
                :global(.theme-moshy) & {
                    border-color: $purple-dark;
                    background-color: $purple-dark;
                }
                .item-number {
                    border-color: $white;
                }
                .item-number,
                .item-text {
                    color: $white;
                    :global(.theme-moshy) & {
                        font-weight: $font-weight-bold;
                    }
                }
                .info-button {
                    svg path,
                    svg polygon {
                        color: $white;
                    }
                }
            }
        }
    }
    &.loading {
        .answers-list {
            .answer-item:hover {
                border-color: initial;
                background-color: initial;
                .item-number {
                    border-color: initial;
                }
                .item-number,
                .item-text {
                    color: initial;
                }
                .info-button svg path,
                .info-button svg polygon {
                    color: initial;
                }
                &.item-selected {
                    .item-number {
                        border-color: initial;
                    }
                    .item-number,
                    .item-text,
                    .info-button svg path,
                    .info-button svg polygon {
                        color: initial;
                    }
                }
            }
        }
    }
    .quiz-info {
        &--wrapper {
            svg {
                path,
                polygon {
                    color: $green-faint-dark;
                    :global(.theme-moshy) & {
                        color: $purple-dark;
                    }
                }
            }
        }
        &--message {
            color: $green-faint-dark;
            :global(.theme-moshy) & {
                color: $grey-400;
            }
        }
    }
}
